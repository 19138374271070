import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import Video from '../assets/img/video.mp4';
import IMG from '../assets/img/logo2.png';
import Card from 'react-bootstrap/Card';

const Home = () => (
    <div>
        <Navbar />

        <Card style={{backgroundColor:"#343a40"}}>
            <video variant="top" src={Video} autoPlay muted loop style={{width:"100%", border: "black"}}></video>
            <img alt="logo" src={IMG} style={{height:"40%", width:"auto", position:"absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)", opacity:"0.95"}}/>
        </Card>

        <Footer />
    </div>
)

export default Home;