import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import Image from 'react-bootstrap/Image';
import Kevin from '../assets/img/kevin.jpg';
import Card from 'react-bootstrap/Card';
import Ghost from '../assets/img/ghost.jpeg';


const Artist = () => (
    <div>
        <Navbar />
        <div>
                <img alt="ghost background" src={Ghost} style={{width:"100%", height:"700px", position:"relative"}}/>
        </div>
            <Card style={{backgroundColor:"#343a40", color:"white", padding:"30px"}}>
                <h1 style={{textAlign:"center", paddingBottom:"10px"}}>Kevin Smith</h1>
            <Image style={{height:"400px", width:"auto", display: "block", margin:"auto", paddingBottom:"10px"}} src={Kevin} rounded />
                <Card.Body style={{textAlign:"justify"}}> 
                <p style={{textIndent:"7%"}}>My name is Kevin Smith and I’ve been tattooing in Charlotte since 2004. I have a very diverse style of tattooing with a 
                    concentration in black & gray realism, American traditional and Japanese style tattooing. I’m not hesitant about taking on 
                    most styles for any tattoo ideas or projects you may have.</p>
                <br />
                <p style={{textIndent:"7%"}}>In 2020 I decided to branch out on my own and create art on my own terms, thus began Revenant Permanent Gallery. 
                    I set out to make a tattoo studio unlike any other. My goal is to create an atmosphere that doesn’t have a typical tattoo shop feel 
                    (believe it or not we’re not all egocentric wannabe badasses who do drugs and listen to screaming heavy metal). 
                    Through my 16 years of tattooing experience working in various studios, I’ve learned what I love about tattooing, what I hate about 
                    tattoo shops, and what is now possible for the best experience for my clients.</p>
                <br />
                <p style={{textIndent:"7%"}}>My goal for Revenant Permanent Gallery is simple; create a sophisticated art gallery atmosphere that is warm, 
                    safe and inviting for whom ever walks through the door, while creating beautiful permanent art that my clientele 
                    (and future clientele) will love and love to show off for years to come. </p>

            </Card.Body>
            </Card>


        <Footer />
    </div>
)

export default Artist;