import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import classes from './Navbar.module.css';
import logo from '../../assets/img/logo.jpg';
import { Link } from 'react-router-dom';

const navbar = () => (
    <div>
              <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand><Link to="/"><img src={logo} alt="logo" className={classes.Logo} /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>
                    <Nav.Link><Link to="/artist" style={{color: "white", textDecoration: "none", cursor: "pointer", fontSize: "20px"}}>The Artist</Link></Nav.Link>
                    <Nav.Link><Link to="/portfolio" style={{color: "white", textDecoration: "none", cursor: "pointer", fontSize: "20px"}}>Portfolio</Link></Nav.Link>
                    <Nav.Link><Link to="/contact" style={{color: "white", textDecoration: "none", cursor: "pointer", fontSize: "20px"}}>Tattoo Inquiry</Link></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                </Navbar>
    </div>
);


export default navbar;