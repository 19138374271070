import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';
import classes from './Portfolio.module.css';
import Port from '../assets/img/port.jpg';
import A from '../assets/img/1.jpg';
import B from '../assets/img/2.jpg';
import C from '../assets/img/3.jpg';
import D from '../assets/img/4.jpg';
import E from '../assets/img/5.jpg';
import F from '../assets/img/6.jpg';
import G from '../assets/img/7.jpg';
import H from '../assets/img/8.jpg';
import I from '../assets/img/9.jpg';
import J from '../assets/img/10.jpg';
import K from '../assets/img/11.jpg';
import L from '../assets/img/12.jpg';
import M from '../assets/img/13.jpg';
import N from '../assets/img/14.jpg';
import O from '../assets/img/15.jpg';
import P from '../assets/img/16.jpg';
import Q from '../assets/img/17.jpg';
import R from '../assets/img/18.jpg';
import S from '../assets/img/19.jpg';
import T from '../assets/img/20.jpg';
import U from '../assets/img/21.jpg';
import V from '../assets/img/22.jpg';
import W from '../assets/img/24.jpg';
import X from '../assets/img/25.jpg';
import Z from '../assets/img/27.jpg';
import AB from '../assets/img/28.jpg';
import BB from '../assets/img/29.jpg';
import CB from '../assets/img/30.jpg';
import EB from '../assets/img/32.jpg';
import FB from '../assets/img/33.jpg';
import GB from '../assets/img/34.jpg';
import HB from '../assets/img/35.jpg';
import IB from '../assets/img/36.jpg';
import KB from '../assets/img/38.jpg';
import LB from '../assets/img/39.jpg';
import MB from '../assets/img/40.jpg';

const Portfolio = () => (
    <div>
        <Navbar />
        <div style={{position:"relative"}}>
                <img alt="portfolio background" src={Port} style={{width:"100%", position:"relative", marginBottom:"30px"}}/>
                <h1 className={classes.h1} style={{position:"absolute", top:"65%", left:"50%", transform: "translate(-50%, -50%)", fontSize:"50px"}}>PORTFOLIO</h1>
        </div>

        <div style={{width:"90%", margin:"auto"}}>

        <section className={classes.Cards} >
                <div style={{margin:"0"}}>
                    <img src={A} alt="" />
                </div>
                <div>
                    <img src={B} alt="" />
                </div>
                <div>
                    <img src={C} alt="" />
                </div>
                <div>
                    <img src={D} alt="" />
                </div>
                <div>
                    <img src={E} alt="" />
                </div>
                <div>
                    <img src={F} alt="" />
                </div>
                <div>
                    <img src={G} alt="" />
                </div>
                <div>
                    <img src={H} alt="" />
                </div>
                <div>
                    <img src={I} alt="" />
                </div>
                <div>
                    <img src={J} alt="" />
                </div>
                <div>
                    <img src={K} alt="" />
                </div>
                <div>
                    <img src={L} alt="" />
                </div>
                <div>
                    <img src={M} alt="" />
                </div>
                <div>
                    <img src={N} alt="" />
                </div>
                <div>
                    <img src={O} alt="" />
                </div>
                <div>
                    <img src={P} alt="" />
                </div>
                <div>
                    <img src={Q} alt="" />
                </div>
                <div>
                    <img src={R} alt="" />
                </div>
                <div>
                    <img src={S} alt="" />
                </div>
                <div>
                    <img src={T} alt="" />
                </div>
                <div>
                    <img src={U} alt="" />
                </div>
                <div>
                    <img src={V} alt="" />
                </div>
                <div>
                    <img src={W} alt="" />
                </div>
                <div>
                    <img src={X} alt="" />
                </div>
                <div>
                    <img src={Z} alt="" />
                </div>
                <div>
                    <img src={AB} alt="" />
                </div>
                <div>
                    <img src={BB} alt="" />
                </div>
                <div>
                    <img src={CB} alt="" />
                </div>
                <div>
                    <img src={EB} alt="" />
                </div>
                <div>
                    <img src={FB} alt="" />
                </div>
                <div>
                    <img src={GB} alt="" />
                </div>
                <div>
                    <img src={HB} alt="" />
                </div>
                <div>
                    <img src={IB} alt="" />
                </div>
                <div>
                    <img src={KB} alt="" />
                </div>
                <div>
                    <img src={LB} alt="" />
                </div>
                <div>
                    <img src={MB} alt="" />
                </div>
        </section>

        </div>

        <Footer />
    </div>
)

export default Portfolio;