import React from 'react';
import './App.css';
import HomePage from './pages/HomePage.js';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import ArtistPage from './pages/ArtistPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div>
    <Route exact path="/" component ={HomePage} />
    <Route exact path="/portfolio" component ={PortfolioPage} />
    <Route exact path="/artist" component ={ArtistPage} />
    <Route exact path="/contact" component ={ContactPage} />
      </div>
    </Router>
  );
}

export default App;