import React from 'react';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
    React.useEffect(() => { window.scrollTo(0, 0); }, [])
    return(
    <div>
            <Card.Footer className="text-center" style={{backgroundColor: "#343a40", bottom:"0"}}>
            <Card.Body style={{color: "white"}}>
                <Card.Title style={{fontSize:"20px"}}>114 Main St. Huntersville, NC - 28078</Card.Title>
                <div style={{padding: "10px"}}></div>
                <Card.Title>(704) 330 - 3449</Card.Title>
                <div style={{padding: "10px"}}></div>
                <Card.Title>revenant.ghost.gallery@gmail.com</Card.Title>
                <div style={{padding: "10px"}}></div>
                <div><a style={{color:"white", cursor:"pointer"}} href="https://instagram.com/revenantpermanentgallery?igshid=1771oqaenz28d" target="_blank" rel="noopener noreferrer"><i><FontAwesomeIcon icon={faInstagram} size="lg"/></i></a></div>
                <div style={{padding: "20px"}}></div>
                <div style={{fontSize: "12px"}}>&copy;2020 - Revenant Permanent Gallery</div>
            </Card.Body>
            </Card.Footer>
            
    </div>)
};


export default Footer;