import React from 'react';
import Navbar from '../components/navbar/Navbar';
import Card from 'react-bootstrap/Card';
import Footer from '../components/footer/Footer';
import Button from 'react-bootstrap/Button';

const Contact = () => (
  <div>
      <Navbar/>
      <div style={{padding:"40px 0"}}>
      <Card className="text-center" style={{width:"80%", margin:"auto"}}>
        <Card.Header style={{backgroundColor: "#343a40", color:"white"}}>Revenant Permanent Gallery</Card.Header>
        <Card.Body>
          <Card.Title>Tattoo Inquiry</Card.Title>
          <Card.Text>
            Looking for a quote? Ready to schedule or want more information?
          </Card.Text>
          <Button href="https://forms.gle/9Nqork3ctdHehLBs9" variant="secondary" target="_blank" rel="noopener noreferrer">Click Here</Button>
        </Card.Body>
      </Card>
      </div>
      <Footer />
  </div>
)

export default Contact;